import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin)

// Get the button:
let scrollToTopBtn = document.getElementById("back-to-top");

// When the user scrolls down 200px from the top of the document, show the button

function initScroll() {
  // window.onscroll = function() {scrollFunction()};

  // Setup a timer
  var timeout;
    
  // Listen for resize events
  window.addEventListener('scroll', function ( event ) {

    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function () {
      scrollFunction();
    });

  }, false);
}

function scrollFunction() {
  if (
    (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300)
  ) {
    scrollToTopBtn.classList.add("show");
  }  else {
    scrollToTopBtn.classList.remove("show");
  }
}

export default function() {
  document.addEventListener('DOMContentLoaded', function() {
    
    if (!scrollToTopBtn) return;

    initScroll();

    scrollToTopBtn.addEventListener('click', function() {
      gsap.to(window, { duration: 1, scrollTo: 0 });
    });
  });
}